import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import 'keen-slider/keen-slider.min.css'
import styles from './FeaturedProjectsMobile.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function FeaturedProjectsMobile({ projects }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const initialSlide = 0
  const slidesPerView = isViewportMd ? 2 : 1
  const sliderDisabled = slidesPerView === projects.length || isViewportLg
  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)

  const { sliderRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: projects.length,
    slidesPerView,
    spacing: 16,
    initialSlide,
    sliderDisabled,
    origin: 0,
    loop: false,
    interactionTitle: 'featured-projects-slider'
  })

  return (
    <div className={styles.component}>
      <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
        {projects.map((project, i) => (
          <div key={i} className={cx(styles.slideContainer, 'keen-slider__slide')}>
            <ImageSlide image={project.image} isActiveSlide={i === currentSlide} layoutClassName={styles.slideLayout} />
          </div>
        ))}
      </div>
      <div className={styles.titlesContainer}>
        {projects.map((project, i) => (
          <ProjectTitle key={i} isActiveSlide={i === currentSlide} {...{ project }} />
        ))}
      </div>
    </div>
  )

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}

function ImageSlide({ image, isActiveSlide, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentImageSlide, isActiveSlide && styles.isActiveSlide, layoutClassName)}>
      <div className={styles.imageContainer}>
        <ImageCover aspectRatio={5 / 7} {...{ image }} />
      </div>
    </div>
  )
}

function ProjectTitle({ project, isActiveSlide }) {
  return (
    <div className={cx(styles.componentProjectTitle, isActiveSlide && styles.isActiveSlide)}>
      <HeadingSm h='4'>
        <a
          href={determineDocumentPathSync({ document: project, routeMap })}
          data-x='link-in-featured-projects-section'
          className={styles.anchor}
        >
          {project.title}
          <span className={styles.arrowIconContainer}>
            <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
          </span>
        </a>
      </HeadingSm>
    </div>
  )
}
