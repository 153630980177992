import { useMediaQuery } from '@kaliber/use-media-query'

import { useTranslate } from '/machinery/I18n'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { FeaturedProjectsDesktop } from '/features/pageOnly/FeaturedProjectsDesktop'
import { FeaturedProjectsMobile } from '/features/pageOnly/FeaturedProjectsMobile'

import mediaStyles from '/cssGlobal/media.css'
import styles from './FeaturedProjects.css'

export function FeaturedProjects({ projects, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  return (
    <section data-x='featured-projects' className={cx(styles.component, layoutClassName)}>
      <ContainerLg>
        <div className={styles.headingContainer}>
          <span className={styles.line} />
          <EnterAnimation layoutClassName={styles.headingLayout}>
            <HeadingXl h='3'>{__`our-projects`}</HeadingXl>
          </EnterAnimation>
        </div>
      </ContainerLg>

      {isViewportMd
        ? <FeaturedProjectsDesktop {...{ projects }} />
        : <FeaturedProjectsMobile {...{ projects }} />
      }
    </section>
  )
}
