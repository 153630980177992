import { animated, useSpring } from '@react-spring/web'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './FeaturedProjectsDesktop.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function FeaturedProjectsDesktop({ projects }) {
  const [hoveringProject, setActiveProject] = React.useState({ isHovering: false, projectId: 0 })
  const activeHover = hoveringProject.projectId > 0

  return (
    <div className={styles.component}>
      <ContainerLg layoutClassName={styles.titlesContainerLayout}>
        <div className={styles.titlesContainer}>
          <div className={styles.titlesColumn}>
            {projects.map((project, i) => {
              const id = i + 1
              const isHovering = (id === hoveringProject.projectId) && hoveringProject.isHovering

              return <ProjectTitle key={i} onHover={handleHover} {...{ project, id, isHovering }} />
            })}
          </div>
        </div>
      </ContainerLg>

      <div className={cx(styles.imagesColumn, activeHover && styles.activeHover)}>
        {projects.map((project, i) => {
          const id = i + 1
          const isHovering = (id === hoveringProject.projectId)
          const image = project.image

          if (!image) return null

          return <Image key={i} {...{ image, isHovering }} />
        })}
      </div>
    </div>
  )

  function handleHover(id, isHovering) {
    setActiveProject({
      isHovering,
      projectId: id
    })
  }
}

function ProjectTitle({ id, project, onHover, isHovering, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl) ?? false
  const arrowOffset = isViewportLg ? '125px' : '100px'

  const { x, opacity } = useSpring({
    x: isHovering ? arrowOffset : isViewportXl ? '0px' : '48px',
    opacity: isHovering ? 1 : 0,
    config: { mass: 0.7, tension: 100, friction: 12 },
  })

  return (
    <div
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <HeadingSm h='4'>
        <animated.a
          href={determineDocumentPathSync({ document: project, routeMap })}
          data-x='link-in-featured-projects-section'
          className={styles.componentProjectTitle}
          style={{ x }}
        >
          <animated.span className={styles.iconContainer} style={{ opacity }}>
            <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
          </animated.span>
          {project.title}
        </animated.a>
      </HeadingSm>
    </div>
  )

  function handleHover(x) {
    onHover(id, x)
  }
}

function Image({ image, isHovering, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentImage, isHovering && styles.isHovering, layoutClassName)}>
      <ImageCover aspectRatio={5 / 7} {...{ image }} />
    </div>
  )
}
